<template>
<div>
  <b-container fluid>
    <div class="d-flex justify-content-center" v-if="loading"><div class="spinner"></div></div>
    <b-row v-if="!loading" align-h='center'>
      <b-col cols="12" md='8'>
        <h2 class='team-title d-none d-md-block'></h2>
        <div class="teamlist-table">
          <b-table head-variant="dark" responsive v-if="teamList.length > 0" striped hover :items="teamList" :fields="fields"
                  @row-clicked='onTeamListRowClicked'>
            <template #cell(actions)="row">
              <b-button size="sm" @click.stop="addTeam(row.item.id)">Add</b-button>
            </template>
          </b-table>
        </div>
        <ul v-if="errors && errors.length">
          <li v-for="error of errors"
              :key="error.id">
            {{error.message}}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import TeamSnap from '@/mixins/TeamSnap'
import { store } from '../store'

export default {
  name: 'AddTeam',
  mixins: [TeamSnap],
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: 'TeamSnap Teams',
          sortable: false,
          'class': 'text-left text-nowrap'
        },
        {
          key: 'leagueName',
          label: 'League Name',
          'class': 'text-left text-nowrap d-none d-md-table-cell',
          sortable: false
        },
        {
          key: 'actions',
          label: '',
          'class': 'text-center d-none d-md-table-cell'
        }
      ],
      teamList: [],
      loading: true,
      errors: []
    }
  },
  created () {
    this.setNav()
    this.getUserInfo().then(tsUser => {
      this.getTeams(tsUser.id).then(teams => {
        this.teamList = teams
        this.loading = false
      })
    })
  },
  methods: {
    setNav () {
      store.commit('updateNav', {
        backLink: '/teamlist',
        teamLink: '/teamlist',
        teamName: 'Back to My Teams'
      })
    },
    onTeamListRowClicked (item) {
      this.$router.push({
        name: 'AddTeamDetail',
        params: { teamId: item.id}
      })
    },
    addTeam (tsTeamId) {
      this.$router.push({
        name: 'AddTeamDetail',
        params: { teamId: tsTeamId}
      })
    }
  }
}
</script>